import React, { useState } from 'react';
import './Generate.scss';
import { logout, getCurrentCredentials } from './CredentialsHandler';
import { useHistory } from 'react-router-dom';
import api from './api';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const initialCodeList = Object.freeze({
    codes: []
});

function Generate() {

    const history = useHistory();

    const [codeList, updateCodeList] = useState(initialCodeList);

    const [isProcessing, updateIsProcessing] = useState(false);

    const [copied, updateCopied] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        updateIsProcessing(true);
        updateCopied(false);

        try {
            const options = {
                headers: { 'mss2dauth': 'mss2d-0eb091aba3b64aa9a4b911f6ae0d607d' }
            }

            let response = await api.post('access-codes-2d/create', getCurrentCredentials(), options);

            if (response.status === 200) {
                let code = response.data;

                updateCodeList({
                    codes: [{
                        accessCode: code.accessCode,
                        expirationDate: new Date(code.expirationDate).toLocaleDateString(),
                        url: process.env.REACT_APP_MASK_SELECTOR_URL + "/?code=" + code.accessCode
                    }]
                });
            } else {
                alert("Error occurred when generating code."); //TODO: Make this into an error div
                console.log("Error occurred when generating code.");
            }
        } catch (error) {
            alert("Error occurred when generating code."); //TODO: Make this into an error div
            console.log("Error occurred when generating code.");
        }

        updateIsProcessing(false);
    };

    const handleLogout = (e) => {
        e.preventDefault();
        logout();
        history.push("/");
    };

    return (
        <div className="generate">
            <div className="generate-instructions">
                <div>{process.env.REACT_APP_GENERATE_INSTRUCTIONS_1}</div>
                <div>{process.env.REACT_APP_GENERATE_INSTRUCTIONS_2}</div>
            </div>
            <form onSubmit={handleSubmit}>
                <div className="input-wrapper">
                    <input type="submit" disabled={isProcessing} value={process.env.REACT_APP_GENERATE_CODE}/>
                </div>
            </form>
            <div className="generate-instructions">
            </div>
            <div className="code-list">
                <div className="input-wrapper">
                    {codeList.codes.map(code =>
                        <div className="code" key={code.accessCode}>
                            <div>
                                <a target="_blank" rel="noopener noreferrer" href={code.url}>{code.url}</a>
                                <CopyToClipboard text={code.url}
                                                 options={{ 'format': 'text/plain' }}
                                                 onCopy={() => updateCopied(true)}>
                                    <button>{process.env.REACT_APP_COPY}</button>
                                </CopyToClipboard>
                                {copied ? <span className={'fade-in'}>Copied.</span> : null}
                            </div>

                            <div style={{ margin: 30 }}>
                                <span
                                    className={'optional-message'}>{process.env.REACT_APP_PATIENT_ACCESS_CODE_DESCRIPTION}</span>
                                <span>{code.accessCode}</span>
                            </div>

                            <div>Expires: {code.expirationDate}</div>
                        </div>
                    )}
                </div>
            </div>
            <div className="logout">
                <a href="/generate" onClick={handleLogout}>{process.env.REACT_APP_LOGOUT}</a>
            </div>
            <div style={{ textAlign: 'center' }}>Version: {process.env.REACT_APP_PORTAL_VERSION}</div>
        </div>
    );
}

export default Generate;