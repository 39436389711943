import React, { useState } from 'react';
import './Login.scss';
import { isLoggedIn, validateCredentials } from './CredentialsHandler';
import { Redirect, useHistory } from 'react-router-dom';

const initialFormData = Object.freeze({
    licenseCode: "",
    pin: ""
});

function Login() {

    const history = useHistory();

    const [formData, updateFormData] = useState(initialFormData);

    const [invalidCredentials, updateInvalidCredentials] = useState(false);

    const [isProcessing, updateIsProcessing] = useState(false);

    const handleChange = (e) => {
        updateFormData({
            ...formData,
            [e.target.name]: e.target.value.trim()
        });
        updateInvalidCredentials(false);
    };

    const handleLicenseKeyUp = (e) => {
        let ele = document.getElementById(e.target.id);
        ele = ele.value.split('-').join('');
        document.getElementById(e.target.id).value = ele.match(/.{1,4}/g).join('-').toUpperCase();
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        updateIsProcessing(true);

        if (await validateCredentials(formData)) {
            history.push("/generate");
        } else {
            updateInvalidCredentials(true);
        }

        updateIsProcessing(false);
    };

    return isLoggedIn() ?
        (
            <Redirect to="/generate"/>
        )
        :
        (
            <div className="login">
                <div className="login-instructions">{process.env.REACT_APP_LOGIN_INSTRUCTIONS}</div>
                <form onSubmit={handleSubmit}>
                    <div className="input-wrapper license-fields">
                        <input name="licenseCode" id="id" type="text"  maxLength={19} onChange={handleChange} onKeyUp={handleLicenseKeyUp}
                               required placeholder={process.env.REACT_APP_LICENSE_KEY}/>
                        <input name="pin" type="password" onChange={handleChange} required
                               placeholder={process.env.REACT_APP_PIN}/>
                        <input type="submit" value={process.env.REACT_APP_LOGIN} disabled={isProcessing}/>
                        {
                            invalidCredentials &&
                            <div className="error">{process.env.REACT_APP_INVALID_CREDENTIALS}</div>
                        }
                    </div>
                </form>
                <div style={{ textAlign: 'center' }}>Version: {process.env.REACT_APP_PORTAL_VERSION}</div>
            </div>
        );
}

export default Login;